import { atom, useAtom } from "jotai";

import { NFT } from "types/models/nft";

export const listNftAtom = atom<NFT[]>([] as any);

export const useGetNftDetail = (id: string) => {
  const [listMft] = useAtom(listNftAtom);
  return listMft.find((e) => e._id === id);
};

export const currentFilterNft = atom<string>("all");

export const processTransactionState = atom<
  "default" | "pending" | "failed" | "success"
>("default");

export const purchaseTypeAtom = atom<"crypto" | "fiat">("crypto");

export const processStripeStateAtom = atom<
  "default" | "pending" | "processing" | "failed" | "success"
>("default");
