import React from "react";
import Image from "next/image";
import { FeedbackItem } from "views/NFTMarket/types";
import { DisplayName } from "components/common/DisplayNameCollection";

export const FeedBackItem: React.FC<{ data: FeedbackItem }> = ({ data }) => {
  const { creator, description, avatar, position } = data;
  return (
    <div
      className="px-8 py-12 2xl:px-16 2xl:py-16  max-w-[1120px] h-full flex justify-center min-h-520px 2xl:min-h-550px mx-0 lg:mx-4 xl:mx-6"
      style={{
        backgroundImage: "url(/images/mask-bg.png)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="flex flex-col items-center ">
        <div className="mt-4">
          <Image
            src={avatar ?? "/icons/default-avatr.svg"}
            width={80}
            height={80}
            alt="default-avatar"
          />
          <p className="text-gradient text-40px font-bold text-center ">
            {creator}
          </p>
        </div>
        <p className="text-gradient text-24px font-medium text-center mb-3 h-12 lg:h-fit">
          <DisplayName name={position} length={100} />
        </p>
        <p className="text-16px text-gray-300 font-medium leading-[28px] text-justify mt-4 lg:mt-0">
          {description}
        </p>
      </div>
    </div>
  );
};
