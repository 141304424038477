import axios from "axios";
import { apiUrlBuilder } from "./apis/apiUrlBuilder";
import { NFTAttribute } from "types/models/nft";
import { formatNftDetailResponse } from "./format";
import { cryptoRandom } from "./cryptoRandom";

export const getNftDetail = async (id: string) => {
  const url = apiUrlBuilder(`nfts/${id}`);
  axios.defaults.timeout = 10000;

  try {
    const res = await axios.get(url);
    return {
      props: {
        data: formatNftDetailResponse(res),
      },
    };
  } catch (error) {
    try {
      const data = await axios.get(
        `${process.env.NEXT_PUBLIC_INTERNAL_IP}/nfts/${id}`
      );
      return {
        props: {
          data: formatNftDetailResponse(data),
        },
      };
    } catch (error) {
      return {
        props: {
          data: {},
        },
      };
    }
  }
};

export const formatTotalTraits = (
  attributeRes: NFTAttribute[] | undefined
): {
  label: string;
  value: string;
}[] => {
  if (!attributeRes || !attributeRes?.length) {
    return [];
  }
  return attributeRes
    .filter((e) => e.key !== "Rare")
    .map((attribute) => {
      return {
        label: attribute.key,
        value: attribute.key,
        data: attribute.value,
      };
    });
};

function shuffleArray(array: any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(cryptoRandom() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export const fillNft = (originalNfts: any[]) => {
  if (originalNfts?.length === 8) {
    return originalNfts;
  }

  shuffleArray(originalNfts);

  const data = [];
  const restOfNfts = 8 - originalNfts.length;
  for (let i = 0; i < restOfNfts; i++) {
    const randomNft = originalNfts[Math.floor(cryptoRandom() * originalNfts.length)]
    data.push({...randomNft, _id: `${randomNft?._id}_${i}`});
  }
  return originalNfts.concat(data);
};
