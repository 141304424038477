import React from "react";
import { GameDetail } from "views/NFTMarket/types";
import Image from "next/image";
import { WrapCustomBorder } from "components/WrapContent/WrapCustomBorder";
import { customCSS } from "./TrendingGame";

export const KeyFeatures = ({ keyFeatures }: { keyFeatures: GameDetail }) => {
  const { title, description, items } = keyFeatures ?? {};
  return (
    <>
      <div className="flex justify-between w-full flex-col px-4 3xl:px-0 pt-20">
        <h1 className="text-40px font-bold text-gradient text-center">
          {title}
        </h1>

        <div className=" block text-gray-300 text-start max-w-[900px] 3xl:max-w-[1180px] mx-auto leading-[28px] text-[16px]">
          <p>
            <div
              dangerouslySetInnerHTML={{
                __html: `<div class="description-container"> ${customCSS}${description}</div>`,
              }}
              className="text-justify overflow-hidden"
            />
          </p>
        </div>
      </div>
      <section className="pb-10 lg:pb-20 scroll-container px-4 lg:px-10 3xl:px-0">
        <div className="container flex flex-col py-[3em] gap-[1em] h-full">
          <div className="grid grid-cols-3 gap-8 min-w-[1000px] mt-6">
            {items?.map((e) => {
              return (
                <div className="flex flex-col" key={e.title}>
                  <div className="relative min-w-[300px] relative">
                    <WrapCustomBorder sameWidth className="z-10" />

                    <div className="px-[5px] pt-[5px]">
                      <Image
                        src={e.image}
                        alt={e.image}
                        width={544}
                        height={306}
                      />
                    </div>
                  </div>
                  <p className="text-gradient text-24px font-medium my-3 text-center">
                    {e.title}
                  </p>
                  <p className="text-gray-300 font-medium leading-[28px] text-16px text-justify">
                    {e.description}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};
