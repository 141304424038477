import React from "react";
import Image from "next/image";
import { StepConfig } from "views/NFTMarket/types";
const steps = [
  {
    title: "Explore the Game",
    description:
      "Start with a freemium experience to explore the gameplay, then purchase your NFT character to unlock the full game. The NFT card is your asset.",
    image: "/images/step1.png",
  },
  {
    title: "Earn EWA Tokens",
    description:
      "Play in the Elowyn Matches, Tournaments, and Quests to earn and share EWA tokens and fund solutions for a bountiful planet and promising future.",
    image: "/images/step2.png",
  },
  {
    title: "Transform the Moloch Game",
    description:
      "Retrieve and assemble the Elowyn Keys to transform the Moloch game of world destruction, and become a creator of the New Legends.",
    image: "/images/step3.png",
  },
  {
    title: "Build the New Civilizations",
    description:
      "Join the DAO Game Guilds to build the new civilizations with your friends and get rewarded for your contributions.",
    image: "/images/step4.png",
  },
  {
    title: "Thrive Together",
    description:
      "Join the movement: 1 Million people playing Elowyn: Quest of Time by December Solstice  2026. Together we can Play2Thrive.",
    image: "/images/step5.png",
  },
];

export const HowItWork = ({ stepsData }: { stepsData: StepConfig }) => {
  const stepRes = stepsData?.items ?? steps
  return (
    <>
      <p className="text-gradient text-40px text-center text-bold">
        {stepsData?.title ?? "How it Works"}
      </p>

      <section className="container mx-auto pb-10 lg:pb-30 scroll-container ">
        <div className="grid grid-cols-5 min-w-[1200px]">
          {stepRes.map((e, index) => {
            return (
              <div key={e.title} className="text-medium text-center">
                <Image src={steps[index].image} width={341} height={341} alt="step" />
                <div className="p-4">
                  <p className="text-gradient text-24px">{e.title}</p>
                  <p className="text-gray-300 text-16px leading-[28px]">
                    {e.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};
