import React from "react";
import Image from "next/image";
import { WrapCustomBorder } from "components/WrapContent/WrapCustomBorder";
import { Box } from "app-sdk";
import styled from "styled-components";
import { SocialItem } from "views/NFTMarket/types";
const WrappedBar = styled.div`
  .social-bar {
    display: none;
  }
  &:hover {
    .social-bar {
      display: flex;
    }
    .show-icon {
      display: none;
    }
  }
`;
export const SocialSideBar = ({ socials }: { socials: SocialItem[] }) => {
  return (
    <WrappedBar className="fixed top-[40%] z-10 right-0 z-11">
      <div className="show-icon mt-20">
        <Image
          src="/icons/prev-pagination.svg"
          width={40}
          height={40}
          alt="prev-pagination"
        />
      </div>
      <div className=" flex flex-col social-bar  bg-[#01010C]  p-5">
        <div className="absolute left-[-20px] top-[45%] ">
          <Image
            src="/icons/prev-pagination.svg"
            width={20}
            height={20}
            alt="prev-pagination"
          />
        </div>
        <WrapCustomBorder sameWidth />

        {socials.map((e) => {
          return (
            <Box
              key={`social-bar-icon-${e.name}`}
              className="cursor-pointer relative z-9999"
            >
              {e.url ? (
                <a href={e.url} target="_blank" rel="noopener noreferrer">
                  <Image src={e?.image} width={40} height={40} alt={e.name} />
                </a>
              ) : (
                <Image src={e.image} width={40} height={40} alt={e.name} />
              )}
            </Box>
          );
        })}
      </div>
    </WrappedBar>
  );
};
