export const collectionFilterOptions = [
  {
    key: "all",
    value: "All",
  },
  {
    key: "popular",
    value: "Popular",
  },
  {
    key: "trending",
    value: "Trending",
  },
  {
    key: "rare",
    value: "Rare Collectibles",
  },
];
export interface GameDetail {
  title: string;
  description: string;
  items: GameItem[];
  storyId: string;
}

export interface GameItem {
  title: string;
  image: string;
  description: string;
}

export interface ChannelInfo {
  name: string;
  trailer_url: string;
  description: string;
  hyper_link: string;
}

export interface Links {
  is_active: boolean;
  link: string;
  type: string;
}

export interface EarlyRegisterInfo {
  title: string;
  description: string;
  images: string[];
  links: Links[];
}

export interface EwaInfo {
  title: string;
  sub_title: string;
  image: string;
  description: string;
}

export interface FeedbackItem {
  creator: string;
  avatar: string;
  description: string;
  position: string;
}

export interface SocialItem {
  name: string;
  type: number;
  url: string;
}

export interface RoadmapItem {
  title: string;
  description: string;
}

export interface CharacterItem {
  creator: string;
  title: string;
  image: string;
  description: string;
}

export interface CharacterDetail {
  title: string;
  description: string;
  items: CharacterItem[];
}
export interface StepConfig {
  title: string;
  items: {
    title: string;
    description: string;
  }[];
}
export interface HomePageData {
  _id: string;
  title: string;
  sub_title: string;
  hashtags: string[];
  description: string;
  channel: ChannelInfo;
  game: GameDetail;
  charactor: CharacterDetail;
  early_register: EarlyRegisterInfo;
  ewa: EwaInfo;
  feedbacks: FeedbackItem[];
  socials: SocialItem[];
  roadmaps: RoadmapItem[];
  is_publish: boolean;
  createdAt: string;
  updatedAt: string;
  secure: any;
  links: ILinkDetail[];
  subscribe: {
    link: string;
    title: string;
  };
  steps: StepConfig;
}

export interface ILinkDetail {
  link: string;
  is_active: boolean;
  type: "external" | "internal";
}

export const TypeOfGroupNft = {
  "1": "trending",
  "2": "popular",
  "3": "rare",
};

export interface PurchasedEventData {
  hash: string;
}

export interface IFooterTab {
  title: string;
  links: ILinkDetail[];
}

export interface SocialItem {
  name: string;
  type: number;
  url: string;
  image: string;
}

export interface FooterConfig {
  address: string;
  support_email: string;
  collaboration: string;
  image: string;
}
