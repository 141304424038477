export function apiUrlBuilder(endPoint: string, version?: string) {
  let apiUrl = ''
  let host = (process.env.NEXT_PUBLIC_API as string) || 'https://api.earthwise.games'
  let formattedEndpoint = ''

  if (host.endsWith('/')) host = host.slice(0, -1)

  if (endPoint.startsWith('/')) {
    formattedEndpoint = endPoint.substring(1)
  } else {
    formattedEndpoint = endPoint
  }

  if (version) {
    apiUrl = `${host}/${version}/${formattedEndpoint}`
  } else {
    apiUrl = `${host}/${formattedEndpoint}`
  }

  return apiUrl
}
