import React from "react";
import Image from "next/image";

export const ArrowRight = ({ width = 21, height = 21 }) => {
  return (
    <Image
      src="/icons/arrow-right-icon.svg"
      alt="arrow-right-icon"
      width={width}
      height={height}
    />
  );
};
