import React, { useCallback, useMemo, useState } from "react";
import { TrendingGameItem } from "./items/TrendingGameItem";
import useSWR from "swr";
import { getNftHomePage } from "services";
import { CustomDot } from "./HowToPlay";
import { Carousel } from "react-responsive-carousel";
import useResponsive from "hooks/useResponsive";
import styled from "styled-components";

export const settings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  centerPadding: "27%",
  slidesToScroll: 1,
  centerMode: true,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 2500,
      settings: {
        centerPadding: "26%",
      },
    },
    {
      breakpoint: 2200,
      settings: {
        centerPadding: "20%",
      },
    },
    {
      breakpoint: 1920,
      settings: {
        centerPadding: "18%",
      },
    },
    {
      breakpoint: 1800,
      settings: {
        centerPadding: "15%",
      },
    },
    {
      breakpoint: 1600,
      settings: {
        centerPadding: "14%",
      },
    },
    {
      breakpoint: 1368,
      settings: {
        centerPadding: "12%",
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "5px",
      },
    },
  ],
  appendDots: (dots: any) => (
    <div
      style={{
        bottom: "-60px",
      }}
    >
      <ul>{dots}</ul>
    </div>
  ),
};

export const WrapList = styled.div`
  .control-dots {
    bottom: 110px;
  }
`;

export const TokenOverview = () => {
  const { data: nfts } = useSWR(["get-nfts-homepage"], async () => {
    const data = await getNftHomePage();
    return data;
  });
  const data = useMemo(() => {
    if (!nfts) {
      return [];
    }
    return [nfts?.trending, nfts?.popular, nfts?.rare];
  }, [nfts]);
  const [activeSlide, setActiveSlide] = useState(0);
  const customRenderIndicator = useCallback(
    (clickHandler: any, isSelected: any, index: any, label: any) => (
      <CustomDot key={index} isSelected={isSelected} onClick={clickHandler} />
    ),
    []
  );
  const breakpoints = useResponsive();
  const centerSlidePercentage = useMemo(() => {
    if (breakpoints.is2Xl) {
      return 60;
    }
    if (breakpoints.isLg) {
      return 80;
    }
    return 100;
  }, [breakpoints.is2Xl, breakpoints.isLg]);
  return (
    <section className="h-[600px] ">
      <WrapList className="mt-0 lg:mt-10 mb-50 w-full">
        {!!data?.length && (
          <Carousel
            showArrows={false}
            showStatus={false}
            transitionTime={800}
            renderIndicator={customRenderIndicator}
            centerSlidePercentage={centerSlidePercentage}
            centerMode={true}
            infiniteLoop={true}
            selectedItem={0}
            onChange={setActiveSlide}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
            showThumbs={false}
          >
            {data?.map((e, index) => (
              <div key={e.title}>
                <TrendingGameItem
                  groupNfts={e}
                  isActive={index === activeSlide}
                />
              </div>
            ))}
          </Carousel>
        )}
      </WrapList>
    </section>
  );
};
