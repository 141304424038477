import axios from "axios";
import { apiUrlBuilder } from "utils/apis/apiUrlBuilder";
import HomePageView from "views/HomePage/index";

const HomePage = (props: any) => {
  return <HomePageView data={props.data} />;
};

export default HomePage;

export const getServerSideProps = async () => {
  const url = apiUrlBuilder("homepage");
  axios.defaults.timeout = 10000;
  try {
    const res = await axios.get(url);
    return {
      props: {
        data: {
          ...(res.data ?? {}),
          banner: "/banner.png",
          url: "https://earthwise.games/",
        },
      },
    };
  } catch (error) {
    try {
      const data = await axios.get(
        `${process.env.NEXT_PUBLIC_INTERNAL_IP}/homepage`
      );
      return {
        props: {
          data: {
            ...(data.data ?? {}),
            banner: "/banner.png",
            url: "https://earthwise.games/",
          },
        },
      };
    } catch (error) {
      return {
        props: {
          data: {},
        },
      };
    }
  }
};
