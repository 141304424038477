import React from "react";
import Image from "next/image";
import PrimaryButton from "components/common/button";
import { EwaInfo } from "views/NFTMarket/types";
export const customCSS = `
<style>
  .description-container a {
    text-decoration:underline !important;
    text-decoration-color: #d8d38c !important;
    background:  linear-gradient(180deg, #AF862C 8.59%, #F8F29C 45.7%, #D9BD5C 66.66%, #AD842A 95.83%);
    font-size: 16px;
    background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  }
  line-height:28px;

</style>
`;
export const TrendingGame = ({ ewa }: { ewa: EwaInfo }) => {
  const { sub_title, image, description } = ewa ?? {};

  return (
    <section className="min-h-600px pt-20 xl:mt-10  px-4 xl:px-6 3xl:px-0">
      <div className="container flex  items-center py-[3em] gap-15 h-full justify-between">
        <div className="max-w-[976px]">
          <div className="text-64px text-gradient font-bold flex items-center gap-2 items-center flex justify-center lg:justify-start">
            <p className="text-64px"> You can earn </p>
            <div className="w-1/4 xl:w-fit flex">
              <Image
                src="/icons/ewa.svg"
                alt="ewa-text"
                width={181}
                height={64}
              />
            </div>
          </div>
          <p className="text-gradient-new text-24px font-medium text-center lg:text-left">{sub_title}</p>
          <div className="flex justify-center w-full">
            <div className="flex xl:hidden w-[85%] justify-center">
              <Image
                src={image ?? "/images/earn-ewa.png"}
                width={480}
                height={480}
                alt="earn-ewa"
              />
            </div>
          </div>
          <div className="text-16px text-gray-300 font-medium my-8 leading-[28px] items-center xl:items-start flex flex-col text-center gap-6 xl:text-left">
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: `<div class="description-container"> ${customCSS}${description}</div>`,
                }}
                className="text-justify"
              />
            </div>
            <PrimaryButton text="Comming soon" disabled={true} />
          </div>
        </div>
        <div className="hidden xl:block">
          <Image
            src={image ?? "/images/earn-ewa.png"}
            width={480}
            height={480}
            alt="earn-ewa"
          />
        </div>
      </div>
    </section>
  );
};
