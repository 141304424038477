import classNames from "classnames";
import PrimaryButton from "components/common/button";
import { DisplayName } from "components/common/DisplayNameCollection";
import { ArrowRight } from "components/Icon/ArrowRight";
import { IS_HIDE_TRENDING } from "config";
import Image from "next/image";
import { useCallback } from "react";
import { EarlyRegisterInfo, Links } from "views/NFTMarket/types";

export const SpecialPromotion = ({ bonus }: { bonus: EarlyRegisterInfo }) => {
  const { title, description, images, links } = bonus ?? {};

  const scrollToSubscribeSection = useCallback(() => {
    const activeLink = links?.filter((link: Links) => link.is_active);
    window.open(
      activeLink?.[0].link ?? "https://www.earthwise.global/game-subscribe",
      "_blank",
      "noopener"
    );
  }, [links]);
  return (
    <section
      className={classNames(
        " px-4 lg:px-10 3xl:px-0",
        IS_HIDE_TRENDING ? "mt-5 md:mt-16 lg:mt-20" : "mt-10 md:mt-40 lg:mt-60"
      )}
    >
      <div
        className="container text-white flex h-full py-8 xl:py-15 gap-[2em] items-center justify-between px-8 xl:px-20 flex-col xl:flex-row  min-h-[450px] xl:min-h-[350px] relative"
        style={{
          backgroundImage: "url(/images/mask-bg.png)",
          backgroundSize: "cover",
        }}
      >
        <div className=" text-white text-4xl font-semibold w-full xl:w-2/5 flex flex-col">
          <p className="text-gradient text-40px font-bold">
            <DisplayName name={title} length={50} />
          </p>
          <p className="text-gray-300 text-base mt-2">{description}</p>
          <div className="mt-6">
            <PrimaryButton
              text="Register"
              icon={<ArrowRight />}
              onClick={scrollToSubscribeSection}
            />
          </div>
        </div>
        <div className="">
          <Image
            src={images?.[0] ?? "/images/register.png"}
            width={720}
            height={260}
            alt="register-banner"
          />
        </div>
      </div>
    </section>
  );
};
