import React, { useCallback } from "react";
import { CharactersItem } from "./items/CharacterItem";
import { CharacterDetail } from "views/NFTMarket/types";
import { Carousel } from "react-responsive-carousel";
import classNames from "classnames";
import styled from "styled-components";
import { DisplayName } from "components/common/DisplayNameCollection";

export const CustomDot = ({ isSelected, onClick }: any) => (
  <li
    className={classNames("flex justify-center dot")}
    onClick={onClick}
    style={{
      opacity: isSelected && "1",
    }}
  />
);
CustomDot.displayName = "CustomDot";
const WrapSlider = styled.div`
  .control-dots {
    padding-top: 24px;
  }
  .carousel-root > .carousel-slider {
    min-height: 750px;
    color: transparent;
  }
`;
export const HowToPlay = ({ character }: { character: CharacterDetail }) => {
  const { title, description, items } = character ?? {};
  const customRenderIndicator = useCallback(
    (clickHandler: any, isSelected: any, index: any, label: any) => (
      <CustomDot key={index} isSelected={isSelected} onClick={clickHandler} />
    ),
    []
  );
  return (
    <div className="container text-white h-full pb-0 pt-10 lg:pb-10 ">
      <p className="text-gradient text-center text-60px font-bold">
        <DisplayName name={title} length={50} />
      </p>
      <p className="text-gradient-new text-medium text-24px text-center mx-auto max-w-[1184px] px-4">
        {description}
      </p>
      <WrapSlider className="pt-10 mb-30 2xl:mb-10 w-full relative">
        <Carousel
          showArrows={false}
          showStatus={false}
          transitionTime={1000}
          renderIndicator={customRenderIndicator}
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={50}
          showThumbs={false}
        >
          {items?.map((e) => (
            <div key={`character-${e.title}`}>
              <CharactersItem item={e} />
            </div>
          ))}
        </Carousel>
      </WrapSlider>
    </div>
  );
};
