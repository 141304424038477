import React, { useCallback, useMemo, useRef, useState } from "react";
import YouTube from "react-youtube";
import { ChannelInfo } from "views/NFTMarket/types";
import { WrapCustomBorder } from "components/WrapContent/WrapCustomBorder";
import { Box } from "app-sdk";
import Image from "next/image";
import { getVideoIdFromEmbedLink } from "utils/string";
const opts = {
  height: "100%",
  width: "100%",
  playerVars: {
    autoplay: 0,
  },
  origin: "https://earthwise.games",
};

export const IntroduceVideo = ({ video }: { video: ChannelInfo }) => {
  const iframeRef: any = useRef(null);
  const [playingState, setPlayingState] = useState<"playing" | "pause">(
    "pause"
  );
  const onReady = useCallback((event: any) => {
    iframeRef.current = event.target;
  }, []);
  const onPlay = useCallback(() => {
    iframeRef?.current?.playVideo();
    setPlayingState("playing");
  }, []);
  const onPause = useCallback(() => {
    iframeRef?.current?.pauseVideo();
    setPlayingState("pause");
  }, []);
  const videoId = useMemo(() => {
    return video?.trailer_url && getVideoIdFromEmbedLink(video?.trailer_url);
  }, [video?.trailer_url]);
  const playVideo = useCallback(() => {
    if (playingState === "pause") {
      return onPlay();
    }
    return onPause();
  }, [onPause, onPlay, playingState]);
  return (
    <section
      className="min-h-[250px] sm:h-[300px] lg:h-[500px] xl:h-[800px] 2xl:h-900px relative flex justify-center items-center flex-col"
      id="video"
    >
      <div className="block absolute bottom-[-10px] translate-x-1/2 md:right-[10%] lg:right-[15%] 2xl:right-[20%] bottom-[-3%] lg:bottom-[-10%] z-12 xl:bottom-[15%] 2xl:bottom-[10%] 3xl:bottom-[3%]">
        <a
          href={
            video?.hyper_link ??
            "https://www.youtube.com/playlist?list=PLCw2ubdI_u67KpBM8z8CPxKxJUzIbuup0"
          }
          target="_blank"
          className="flex gap-2 items-center relative z-5"
          rel="noreferrer noopener"
        >
          <p className="text-gradient text-18px mt-1">
            {" "}
            See more trailers at our YouTube Channel
          </p>
          <Image
            src="/icons/next-pagination.svg"
            width={20}
            height={20}
            alt="next-pagination"
            className="mb-1"
          />
        </a>
        <div
          style={{
            background: "linear-gradient(45deg, #555048 0%,#AF8342 100%)",
            height: "2px",
            width: "100%",
          }}
        />
      </div>
      <div className="absolute z-10">
        <Image
          src="/images/bg-video.png"
          width={1920}
          height={960}
          alt="bg-video"
          priority
        />
      </div>
      <Box
        className="w-full h-full absolute z-10 cursor-pointer"
        onClick={playVideo}
      ></Box>
      <div className="container z-1 flex justify-center items-center h-full relative">
        <Box className="w-[70%] h-[70%] relative xl:w-[60%] xl:h-[55%] 3xl:h-[70%]">
          {
            <div className="hidden xl:block z-1">
              <WrapCustomBorder sameWidth={true} />
            </div>
          }
          <YouTube
            videoId={videoId}
            opts={opts}
            onReady={onReady}
            style={{
              height: "100%",
              width: "100%",
            }}
          />
        </Box>
      </div>
    </section>
  );
};
