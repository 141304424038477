import React from "react";
import { CharacterItem } from "views/NFTMarket/types";
import Image from "next/image";
import { DisplayName } from "components/common/DisplayNameCollection";

export const CharactersItem: React.FC<{ item: CharacterItem }> = ({ item }) => {
  const { creator, title, description, image } = item ?? {};
  return (
    <div className="pt-0 md:pt-10 lg:pt-30 pb-6 lg:pb-20 xl:pb-8 3xl:pb-15 flex items-center">
      <div
        className={`relative w-full h-[900px] md:h-[850px] lg:h-[700px] xl:h-[550px] 3xl:h-[650px] px-11 z-10`}
        style={{
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          backgroundImage: "url(/images/mask-bg.png)",
          backgroundSize: "cover",
          // pointerEvents: "none",
        }}
      >
        <div className="absolute top-[-3%] md:top-[-33%] lg:top-[-22%]  xl:top-[-16%] 2xl:top-[-22%]  left-[2%] md:left-[-3%] lg:left-[-2%] w-full  md:w-[90%] lg:w-[80%] xl:w-[55%] 3xl:w-[60%] 2xl:max-w-[800px] 3xl:max-w-[925px]">
          <Image
            src={image ?? "/images/character.png"}
            alt="character"
            width={925}
            height={925}
          />
        </div>

        <div className="flex justify-end h-full gap-8 pb-10 xl:pb-0">
          <div className="hidden xl:flex items-center">
            {" "}
            <Image
              src="/icons/divider.svg"
              width={12}
              height={450}
              alt="divider"
            />
          </div>

          <div className="w-full lg:w-2/5 xl:w-1/2 flex items-center h-full ">
            <div className="h-full flex flex-col justify-end xl:justify-center w-full">
              <div className="block xl:hidden mb-2 ">
                <Image
                  src="/icons/horizental-divider.svg"
                  alt="nft-detail-divider"
                  width={928}
                  height={9}
                />
              </div>
              <div className="px-2 lg:px-6">
                <p className="text-gradient text-40px text-bold text-left relative z-10 w-fit">
                  <DisplayName name={creator} length={30} />
                </p>
                <p className="text-gradient text-24px text-medium text-left">
                  <DisplayName name={title} length={50} />
                </p>
                <p className="text-gray-300 font-medium leading-[28px] text-16px text-justify overflow-hidden">
                  {description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
