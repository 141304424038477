import React, { useCallback, useMemo } from "react";
import { FeedBackItem } from "./items/FeedBackItem";
import { FeedbackItem } from "views/NFTMarket/types";
import { Carousel } from "react-responsive-carousel";
import { CustomDot } from "./HowToPlay";
import useResponsive from "hooks/useResponsive";

import styled from "styled-components";

const WrapFeedbacks = styled.div`
  .carousel-root {
    height: 100%;
  }
  carousel-root > carousel {
    height: 100%;
  }
  .slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const FeedBack = ({ feedbacks }: { feedbacks: FeedbackItem[] }) => {
  const customRenderDots = useCallback(
    (clickAction: any, isSelected: any, index: any, label: any) => (
      <CustomDot key={index} isSelected={isSelected} onClick={clickAction} />
    ),
    []
  );
  const breakpoints = useResponsive();
  const centerSlidePercentage = useMemo(() => {
    if (breakpoints.is2Xl) {
      return 60;
    }
    if (breakpoints.isLg) {
      return 80;
    }
    return 100;
  }, [breakpoints.is2Xl, breakpoints.isLg]);
  return (
    <section className=" h-[850px] md:h-[700px] mx-auto">
      <WrapFeedbacks className="pt-10 w-full h-full">
        <Carousel
          showArrows={false}
          showStatus={false}
          transitionTime={800}
          renderIndicator={customRenderDots}
          centerSlidePercentage={centerSlidePercentage}
          centerMode={true}
          infiniteLoop={true}
          selectedItem={0}
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={50}
          showThumbs={false}

        >
          {feedbacks?.map((e, index) => (
            <div key={e.creator}>
              <FeedBackItem data={e} />
            </div>
          ))}
        </Carousel>
      </WrapFeedbacks>
    </section>
  );
};
