import { IS_HIDE_TRENDING } from "config";
import { globalData } from "hooks/useGlobalData";
import { useAtom } from "jotai";
import { useMemo } from "react";
import { HomePageData } from "views/NFTMarket/types";
import { CommingSoon } from "./components/CommingSoon";
import { FeedBack } from "./components/FeedBack";
import { HowItWork } from "./components/HowItWork";
import { HowToPlay } from "./components/HowToPlay";
import { IntroduceVideo } from "./components/IntroduceVideo";
import { KeyFeatures } from "./components/KeyFeatures";
import { Overview } from "./components/Overview";
import { Roadmap } from "./components/Roadmap";
import { SocialSideBar } from "./components/SocialSideBar";
import { SpecialPromotion } from "./components/SpecialPromotion";
import { TokenOverview } from "./components/TokenOverview";
import { TrendingGame } from "./components/TrendingGame";

const HomePage = ({ data }: { data: HomePageData }) => {
  const [global, setGlobalData] = useAtom(globalData);
  const {
    channel,
    charactor,
    game,
    ewa,
    feedbacks,
    early_register,
    roadmaps,
    secure,
    links,
    description,
    subscribe,
    steps,
  } = data ?? {};
  useMemo(() => {
    if (subscribe) {
      setGlobalData({ ...global, subscribe: subscribe });
    }
  }, []);
  return (
    <div className="relative">
      <SocialSideBar socials={data.socials} />
      <Overview links={links} description={description} />
      <IntroduceVideo video={channel} />
      <KeyFeatures keyFeatures={game} />
      <HowItWork stepsData={steps} />
      <HowToPlay character={charactor} />
      {!IS_HIDE_TRENDING && <TokenOverview />}
      <SpecialPromotion bonus={early_register} />
      <TrendingGame ewa={ewa} />
      <FeedBack feedbacks={feedbacks} />
      <CommingSoon secure={secure} />
      <Roadmap roadmaps={roadmaps} />
    </div>
  );
};
export default HomePage;
