import React from "react";

export const Line = ({ color }: { color?: string }) => {
  return (
    <svg
      width="289"
      height="3"
      viewBox="0 0 289 3"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        y1="1.49219"
        x2="288.5"
        y2="1.49219"
        stroke={color}
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1031_2913"
          x1="85.3894"
          y1="2.57812"
          x2="85.3894"
          y2="3.45052"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="0.425373" stopColor="#F8F29C" />
          <stop offset="0.665625" stopColor="#D9BD5C" />
          <stop offset="1" stopColor="#AD842A" />
        </linearGradient>
      </defs>
    </svg>
  );
};
