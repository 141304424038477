import Tooltip from "antd/lib/tooltip";
import { Box } from "app-sdk";
import { truncateWithEllipsis } from "utils/string";

export const DisplayName: React.FC<{
  name: string;
  length?: number;
}> = ({ name, length = 26 }) => {
  const title = name?.length > length ? name : "";
  return (
    <Tooltip
      title={title}
      placement="top"
      style={{
        cursor: "pointer",
      }}
    >
      <Box>{truncateWithEllipsis(name, length)}</Box>
    </Tooltip>
  );
};
