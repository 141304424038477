import React, { useCallback, useMemo, useState } from "react";
import Image from "next/image";
import styled from "styled-components";
import { WrapCustomBorder } from "components/WrapContent/WrapCustomBorder";
import PrimaryButton from "components/common/button";
import { useRouter } from "next/router";
import classNames from "classnames";
import { currentFilterNft } from "hooks/useNFT";
import { useAtom } from "jotai";
import { TypeOfGroupNft } from "views/NFTMarket/types";
import useResponsive from "hooks/useResponsive";
import { fillNft } from "utils/nft";

const ImageStack = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const ImageItem = styled.li<{
  indexNft: number;
  length: number;
}>`
  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(255, 122, 0, 0.5);
    scale: 1.01;
  }
  position: absolute;
  cursor: pointer;
  top: 0;
  left: ${({ indexNft }) => {
    return `${Number(indexNft) * 10}%`;
  }};
  z-index: ${({ length, indexNft }) => {
    return length - indexNft;
  }};
  display: inline-block;

  @media (max-width: 768px) {
    left: ${({ indexNft }) => {
      return `${Number(indexNft) * 5}%`;
    }};
  }
`;

const HoverState = styled.div`
  position: relative;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 20px 40px 0px #eec34d inset;
  }
`;

const WrapList = styled.div<{
  isActive: boolean;
}>``;

export const TrendingGameItem: React.FC<{
  groupNfts: any;
  isActive: boolean;
}> = ({ groupNfts, isActive }) => {
  const { data: originalNfts, title, index } = groupNfts ?? {};
  const [, setCurrentFilter] = useAtom(currentFilterNft);
  const router = useRouter();
  const data = useMemo(() => {
    return fillNft(originalNfts);
  }, [originalNfts]);
  const handleDirectToMarket = useCallback(() => {
    setCurrentFilter((TypeOfGroupNft as any)[index]);
    return router.push("/collection");
  }, [index, router, setCurrentFilter]);
  const { isXs } = useResponsive();
  const renderImageData: any = useMemo(() => {
    if (isXs) {
      return data.slice(0, 6);
    }
    return data;
  }, [data, isXs]);

  const [hoverId, setHoverId] = useState("");
  const onMouseLeave = useCallback(() => setHoverId(""), []);
  return (
    <>
      {" "}
      <h1
        className={classNames(
          "text-40px font-bold text-center text-gradient  mt-0 mb-10 md:mt-10",
          {
            invisible: !isActive,
          }
        )}
      >
        {title}
      </h1>
      <WrapList
        isActive={isActive}
        className={classNames(
          "slide-item pt-10 pb-0 md:pb-10 justify-center flex h-[400px] md:h-[460px] max-w-[340px] sm:max-w-[600px] lg:max-w-[700px] xl:max-w-[880px] 2xl:max-w-[950px] 3xl:max-w-[1300px] flex-col mx-auto",
          {
            "active-item": isActive,
          }
        )}
      >
        <ImageStack onMouseLeave={onMouseLeave}>
          {renderImageData?.map((e: any, index: number) => {
            return (
              <RenderImage
                key={`trending-game-${e._id}`}
                length={renderImageData.length}
                index={index}
                e={e}
                hoverId={hoverId}
                setHoverId={setHoverId}
              />
            );
          })}
        </ImageStack>
      </WrapList>
      <div
        className={classNames(
          "flex justify-center flex-col items-center gap-3 mt-8",
          {
            invisible: !isActive,
          }
        )}
      >
        <PrimaryButton
          text="Buy now"
          className="px-12 w-fit"
          onClick={handleDirectToMarket}
        />
        <p className="text-gray-400 text-16px font-medium mt-1">
          Available September
        </p>
      </div>
    </>
  );
};

const RenderImage = ({ length, e, index, hoverId, setHoverId }: any) => {
  const onHover = useCallback(() => {
    setHoverId(e._id);
  }, [e._id, setHoverId]);
  const isDisabled = hoverId !== e._id;
  return (
    <ImageItem indexNft={index} length={length} onMouseEnter={onHover}>
      <div className="absolute z-10 w-full h-full">
        <WrapCustomBorder isDisabled={isDisabled} />
      </div>
      <HoverState
        style={{
          zIndex: 1000,
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      />
      <div className="w-[230px] md:w-[260px] h-[300px] md:h-[348px]">
        <Image
          src={e.image ?? "/icons/ava-profile.png"}
          width={260}
          height={348}
          alt=""
          priority={false}
        />
      </div>
    </ImageItem>
  );
};
